<template>
  <v-container fluid>
    <Carousel />
  </v-container>
</template>

<script>
import { defineComponent } from '@vue/composition-api'
import Carousel from '@/components/Carousel.vue'

export default defineComponent({
  components: { Carousel },
  name: 'CarouselUpdate',
})
</script>
